.option {

    @apply type-emphasis type-090;

    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    cursor: pointer;
}

.optionDisabled {
    cursor: not-allowed;
    color: theme('colors.content.quiet');
}
